import React, {useEffect, useState, useMemo} from "react";
import queryString from "query-string";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import {dataSourceV2} from "commons-ui/core/DataSource";
import {stripHTML} from "commons-ui/core/utils";
import Loading from "commons-ui/core/Loading";
import {calcPrice, processClothesSize, renderNameVertical} from "ecommerce-commons-ui/core/utils";
import renderHTML from "react-render-html";
import ProductImageWidget from "ecommerce-commons-ui/core/ProductImageWidget";
import {Helmet} from "react-helmet";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ArrowBack from "@material-ui/icons/ArrowBack";
import FeatureSelect from "./FeatureSelect";
import {prettyDateUSA, dateToLocal} from "commons-ui/core/utils/date";
import UpsellingV2 from "ecommerce-commons-ui/core/UpsellingV2";
import "./style.styl";


export default function ProductDescription(props) {
    const {itemIdValue, location, withBreadcrumbs, cart, isGeneral, onAddToCart, mediaPath, noImage, onClose, isPage, locationId, ecommerceStoreId, apiPath, noWatermark, isMobile, onProduct, dispatch} = props;
    const tabs = props.productDescriptionTabs || [];
    const [product, setProduct] = useState(null);
    const [attributeSet, setAttributeSet] = useState({});
    const [featureList, setFeatureList] = useState([]);
    const [errorDict, setErrorDict] = useState({});
    const [loading, setLoading] = useState(true);
    const [attributeLoading, setAttributeLoading] = useState(true);
    const [tab, setTab] = useState("description");


    const upsellCategoryId = Object.keys((product || {}).category_id_dict || {})[0] || "";

    const requestAttributeSet = (attributeSetId) => {
        if (!attributeSetId) {
          return;
        }
        dataSourceV2(`attribute_set_view_get?attribute_set_id=${attributeSetId}`, {
          url: apiPath,
        }).then((v) => {
          if (!v.message && v.item) {
            let item = v.item;
            setAttributeSet(v.item);
            setAttributeLoading(false);
          }
        });
      };

    const request = () => {
        dataSourceV2(`ecommerce_product_view_get?is_search=true&postprocess=${ecommerceStoreId}&slug=${itemIdValue}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item) {
                    let data = v.item;
                    const category_id_dict = data.category_id_dict || {};
                    const version = data.version || {};
                    const product = {...version, category_id_dict, attribute_set_id: data.attribute_set_id};
                    product[props.itemId || "ecommerce_product_id"] = data[props.itemId || "ecommerce_product_id"];
                    requestAttributeSet(data.attribute_set_id);
                    setProduct(product);
                    onProduct ? onProduct(product) : null;
                    setLoading(false);


                }
            });
    };

    useEffect(() => {
        request();
    }, [itemIdValue]);

    const {attributeGroup, attributeViewList} = useMemo(() => {
        let attributeGroup = {};
        let attributeViewList = [];
        Object.values(attributeSet.attribute_group_view_dict || {}).forEach(v => {
            const group = v.attribute_group || {};
            if (group.group_type === "default") {
                attributeGroup = group;
                attributeViewList = Object.values(v.attribute_group_link_view_dict || {});
            }
        });
        attributeViewList.sort((a,b) => ((a.attribute_group_link || {}).position > (b.attribute_group_link || {}).position) ? 1 : (((b.attribute_group_link || {}).position > (a.attribute_group_link || {}).position) ? -1 : 0));
        return {attributeGroup, attributeViewList};
    }, [attributeSet]);



    if (loading) {
        return (
            <div className="product-description-block">
                <div className="u-flex u-alignItemsFlexStart u-sizeFullWidth u-paddingVertical10 u-xs-flexColumn">
                    <div className="u-marginTop4 u-flexCenter u-justifyContentCenter u-paddingHorizontal24 u-xs-sizeFullWidth">
                        <div className="u-width300 u-flexColumn u-alignItemsCenter u-justifyContentCenter u-marginBottom30">
                            <Skeleton className="u-margin10 u-borderRadius4" variant="rect" width={200} height={260}/>
                                <div className="u-sizeFullWidth u-flexCenter u-justifyContentCenter">
                                    <Skeleton className="u-margin5 u-borderRadius3" variant="rect" width={50} height={50}/>
                                    <Skeleton className="u-margin5 u-borderRadius3" variant="rect" width={50} height={50}/>
                                </div>
                        </div>
                    </div>
                    <div className="u-sizeFullWidth u-flexColumn u-paddingHorizontal15 u-xs-padding0">
                        <Skeleton className="u-marginBottom15" variant="text" width={isMobile ? "90%" : "80%"} height={45}/>
                        <Skeleton className="u-marginBottom5" variant="text" width={isMobile ? "60%" : "40%"} height={25}/>
                        <Skeleton className="u-marginBottom5" variant="text" width={isMobile ? "50%" : "40%"} height={25}/>
                        <Skeleton className="u-marginBottom20" variant="text" width={isMobile ? "50%" : "40%"} height={25}/>

                        <Skeleton className="u-xs-hide" variant="text" width={"30%"} height={55}/>
                        <div className="u-flexCenter u-xs-flexColumn">
                            <Skeleton className="u-marginRight10 u-xs-marginHorizontal0" variant="text" width={isMobile ? "100%" : 140} height={80}/>
                            <Skeleton className="" variant="text" width={isMobile ? "100%" : 200} height={80}/>
                        </div>
                    </div>
                </div>

                <div className="u-marginTop20 u-marginBottom50 u-paddingHorizontal24 u-xs-hide">
                    <div className="u-flexCenter u-marginBottom20">
                        <Skeleton className="u-marginRight15" variant="text" width={110} height={60}/>
                        <Skeleton className="" variant="text" width={130} height={60}/>
                    </div>
                    <Skeleton className="u-marginBottom5" variant="text" width={"80%"} height={25}/>
                    <Skeleton className="u-marginBottom5" variant="text" width={"55%"} height={25}/>
                    <Skeleton className="u-marginBottom5" variant="text" width={"55%"} height={25}/>
                    <Skeleton className="u-marginBottom5" variant="text" width={"55%"} height={25}/>
                    <Skeleton className="u-marginBottom5" variant="text" width={"40%"} height={25}/>
                </div>

            </div>
        );
    }

    const hasError = () => {
        const errors = {};

        attributeViewList.forEach((view) => {
            const attribute = (view.attribute_view || {}).attribute || {};
            if (!attribute.values || attribute.values.length === 0) {
                return;
            }
            const attributeSelected = featureList.filter(v => v.attribute_id === attribute.attribute_id)[0];

            if (attribute.is_value_required) {
                if (!attributeSelected || !attributeSelected.values || !attributeSelected.values.length) {
                    errors[attribute.attribute_id] = "Attribute required";
                    return;
                }
            }

            if (attribute.value_selected_min) {
                if (!(attributeSelected && attributeSelected.values && attributeSelected.values.length >= attribute.value_selected_min)) {
                    errors[attribute.attribute_id] = "Minimum required selections: " + attribute.value_selected_min.toString();
                    return;
                }
            }

            if (attribute.value_selected_max) {
                if (!(attributeSelected && attributeSelected.values && attributeSelected.values.length <= attribute.value_selected_max)) {
                    errors[attribute.attribute_id] = "Maximum allowed selections: " + attribute.value_selected_max.toString();
                    return;
                }
            }

        });
        setErrorDict(errors);
        return Object.keys(errors).length !== 0;
    };


    const {price_fixed, discount_fixed, discount_percent} = calcPrice(product, featureList);
    const images = product.images ? product.images.split(",").map(v => mediaPath + v) : [(noImage || "https://assets.smallcase.com/images/smallcases/200/SCSB_0004.png")];
    return (
        <div className="product-description-block">
            {
                withBreadcrumbs ?
                <div className="u-marginVertical20 u-xs-margin0">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" className="u-cursorPointer u-flexCenter" onClick={() => onClose()}>
                            <ArrowBack fontSize="small" className="u-marginRight5"/> Home
                        </Link>
                        <Typography color="textPrimary">{product.name || ""}</Typography>
                    </Breadcrumbs>
                </div>
                    :
                    null
            }
            {
                isPage ?
                    <Helmet>
                        <title>{product.seo_title || (product.name ? product.name.slice(0, 100) : "") || "Product Page."}</title>
                        <meta name="Description" content={product.seo_description || (product.description ? stripHTML(product.description).slice(0, 180) : "") || "Product Page."}/>
                        {
                            product.seo_tags ?
                                <meta name="keywords" content={product.seo_tags}/>
                                :
                                null
                        }
                        <meta property="loaded"/>
                    </Helmet>
                    :
                    null
            }
            <div className="u-flex u-alignItemsFlexStart u-sizeFullWidth u-paddingVertical10 u-xs-flexColumn u-paddingTop20">
                <div className="u-marginTop4 u-marginBottom20 u-xs-sizeFullWidth">
                    <ProductImageWidget items={images}/>
                </div>
                <div className="u-sizeFullWidth u-flexColumn u-paddingHorizontal15 u-xs-paddingHorizontal0 u-maxWidth500 u-marginLeft50 u-xs-margin0">
                        <div className="u-sizeFullWidth u-marginBottom10">
                            {renderNameVertical(product, {isNameH1: true, isNameFull: true, isManufacturerFull: true, noContent: true})}
                            {
                                product.description ?
                                    <div className="u-fontSize15 u-marginVertical5">
                                         {product.description}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        {
                            attributeLoading ?
                                <Loading/>
                                :
                                <div className="u-marginTop10">
                                    <FeatureSelect {...props} product={product} featureList={featureList} errorDict={errorDict} onFeatureListChange={setFeatureList} attributeList={attributeViewList}/>
                                </div>
                        }
                    <div className="u-fontSize11 u-flexCenter u-justifyContentSpaceBetween u-paddingVertical12 u-marginTop10 u-marginBottom10">
                        <div className="u-flexCenter">
                            <div    className={"u-flexCenter u-fontWeightBold u-fontSize24"}
                                    style={{color: "rgb(45, 47, 67)"}}>
                                $ {(discount_percent ? discount_fixed : price_fixed) || "0"}
                            </div>
                            {
                                discount_percent ?
                                    <div className="u-flexCenter u-marginLeft10">
                                        <div    className={"u-flexCenter u-fontSize16"}
                                                style={{color: "#9d9ca5", textDecoration: "line-through"}}>
                                            $ {price_fixed}
                                        </div>
                                        <div className="discount_percent u-marginLeft5">
                                            {discount_percent}%
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>

                    {
                        product.product_available_from || product.product_available_till ?
                            <div>
                                <div className="u-fontSize10  u-paddingVertical15 u-textColorRed">
                                    Available for fulfillment only on these dates
                                    {
                                        product.product_available_from ? (" " + prettyDateUSA(new Date(product.product_available_from)) + "") : ""
                                    }
                                     -
                                    {
                                        product.product_available_till ? (" " + prettyDateUSA(new Date(product.product_available_till)) + "") : ""
                                    }
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        product.is_not_in_stock === "true" ?
                            <div>
                                <div className="not-in-stock-large">
                                    Not in Stock
                                </div>
                            </div>
                            :
                            <div className={"u-marginBottom30 u-xs-flexColumn " + (!isGeneral && false ? "u-hide" : "")}>
                                <Button aria-label="Add Item To Shopping Cart" variant="outlined"
                                        disabled={attributeLoading}
                                        className="u-marginBottom20 button-secondary" color="primary" size="large"
                                        onClick={(event) => {
                                            if (isGeneral && onAddToCart) {
                                                onAddToCart(event.currentTarget);
                                            } else {
                                                if (!hasError()) {
                                                    onAddToCart(featureList)
                                                    return
                                                    //const onAddToCart = (reset) => {
                                                    //    dispatch("cart", { product: item, count: 1, ecommerceStoreId, deliveryItem, reset, featureList}, "add_product");
                                                    //    onClose ? onClose() : null;
                                                    //};
                                                    //if (deliveryItem.name !== cart.deliveryItem.name && (cart.items || []).length !== 0) {
                                                  //      dispatch("dialog_core", {option: {action: ()=>onAddToCart(true)}, isOpen: true, type: "CART_RESET"});
                                                    //} else {
                                                    //  onAddToCart();
                                                    //}
                                                }
                                            }

                                        }}>
                                    <ShoppingCart className="u-marginRight10"/> Add to Cart
                                </Button>
                            </div>
                    }

                    {
                        product.restaurant_ingredients || product.restaurant_ingridients ?
                            <div className="u-flexColumn">
                                <span className="u-fontWeightBold u-fontSize18 u-marginBottom10">
                                    Ingredients:
                                </span>
                                <div className="u-flexColumn">
                                    {
                                        ((product.restaurant_ingredients || product.restaurant_ingridients || "").split("\n")).map((v, i) => {
                                            return (
                                                <span>
                                                    {v}
                                                </span>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            :
                            null
                    }

                </div>
            </div>


            {
                upsellCategoryId && ecommerceStoreId && product ?
                    <div className="u-marginTop50">
                        <UpsellingV2    {...props}
                                        columns={3}
                                        size={6}
                                        isMobile
                                        ecommerceStoreId={ecommerceStoreId}
                                        locationId={locationId}
                                        onItemClick={(id) => dispatch(location.pathname + "?" + queryString.stringify({...queryString.parse(location.search), show_product: id}))}
                                        categoryId={upsellCategoryId}
                                        exclude={[product.ecommerce_product_id]}
                                        label={(
                                            <div className={isMobile ? "u-absolute u-top0 u-sizeFullWidth u-flexCenter u-justifyContentCenter" : ""}>
                                                <div className="u-textColorPrimary u-paddingHorizontal20 u-paddingVertical5" style={{fontFamily: "Piroshky", letterSpacing: "2px"}}>More Products</div>
                                            </div>
                                        )}

                        />
                    </div>
                    :
                    null
            }
        </div>
    );
}
